import React, { useState, useContext, useEffect } from 'react';
import { useSnackbar } from '../../context/SnackBarContext';

import { useHistory, useLocation } from 'react-router-dom';

import { Button, Grid } from '@mui/material';

import otgService from '../../services/otg.service';

import RegistrarCliente from './cliente/RegistrarCliente.component';
import RegistrarProducto from './producto/RegistrarProducto.component';
import { UserContext } from '../../context/UserContext';
import DialogEspere from '../../components/DialogEspere.component';

export default function Registrar() {
	const datosSession = useContext(UserContext);

	const { showSnackbar } = useSnackbar();

	const handleClickSnackbar = (message, severity) => {
		showSnackbar({
			message,
			severity,
		});
	};

	const datosRecibidos = useLocation();
	const [idCliente, setIdCliente] = useState('');

	const history = useHistory();

	const [mostrarEspere, setMostrarEspere] = useState(false);

	const [datosOtg, setDatosOtg] = useState({
		id_estado: 1,
		id_cliente: '',
		id_variacion: '',
		id_colaborador: datosSession.id_colaborador,
		ubi_origen: datosSession.id_ubicacion,
		recibido_por: datosSession.id_colaborador,
		id_canal: 1,
		ubi_destino: 19,
		responsable: '',
		fecha_compra: Date.now(),
		tipo_transporte: 'ida',
	});

	// FUNCIONES

	const guardarDatos = () => {
		setMostrarEspere(true);
		otgService
			.crearOtg(datosOtg)
			.then((response) => {
				setMostrarEspere(false);
				
				// Mostrar notificación de éxito
				handleClickSnackbar('OTG creada con éxito', 'success');
	
				history.push({
					pathname: '/ticket',
					state: {
						id_otg: response.data.otgData.id_otg,
					},
				});
			})
			.catch((err) => {
				setMostrarEspere(false);
	
				let mensajeError = '';
	
				try {
					mensajeError = err.response.data.errors[0].msg;
				} catch (error) {
					mensajeError = 'Error al crear la OTG';
				}
	
				handleClickSnackbar(mensajeError, 'error');
			});
	};

	useEffect(() => {
		try {
			if (datosRecibidos.state.idCliente) {
				setIdCliente(datosRecibidos.state.idCliente);
			}
		} catch (error) {}
	}, [datosRecibidos]);

	useEffect(() => {
		setDatosOtg((datosOtg) => ({
			...datosOtg,
			id_cliente: idCliente,
		}));
	}, [idCliente]);

	return (
		<>
			<DialogEspere open={mostrarEspere} />
			{!idCliente ? (
				<RegistrarCliente
					setIdCliente={(value) => {
						setIdCliente(value);
					}}
				/>
			) : (
				<>
					<RegistrarProducto
						datosOtg={datosOtg}
						setDatosOtg={(value) => {
							setDatosOtg(value);
						}}
					/>
					<Grid container justifyContent='center'>
						<Button
							variant='contained'
							onClick={() => {
								guardarDatos();
							}}
						>
							Guardar
						</Button>
					</Grid>
				</>
			)}
		</>
	);
}
