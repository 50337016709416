import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from '../../context/SnackBarContext';
import {
    Grid,
    Typography,
    FormControlLabel,
    Checkbox,
    Button,
    TextField,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    FormControl,
    FormGroup,
} from '@mui/material';
import Quagga from 'quagga';
import { cambiarEstado, findOneById } from '../../services/otg.service';
import { verificar } from '../../services/auth.service';

export default function Recibir() {
    const { showSnackbar } = useSnackbar();
    const handleClickSnackbar = (message, severity) => {
        showSnackbar({
            message,
            severity,
        });
    };

    const [idOtg, setIdOtg] = useState('');
    const [listaOtgs, setListaOtgs] = useState([]);
    const sessionToken = JSON.parse(localStorage.getItem('colaborador'));
    const [datosSession, setDatosSession] = useState({});
    const [mostrarModal, setMostrarModal] = useState(false);
    const history = useHistory();

    useEffect(() => {
        verificar(sessionToken.token)
            .then((response) => {
                setDatosSession(response.data);
            })
            .catch((err) => {
                history.go(0);
            });

        Quagga.init(
            {
                frequency: 1,
                inputStream: {
                    name: 'Live',
                    type: 'LiveStream',
                    target: document.querySelector('#camera'),
                    constraints: {
                        facingMode: 'environment',
                        width: { min: 640 },
                        height: { min: 480 },
                    },
                },
                decoder: {
                    readers: ['code_128_reader'],
                },
            },
            function (err) {
                if (err) {
                    console.log(err);
                    return;
                }
                Quagga.start();
            }
        );

        Quagga.onProcessed((result) => {
            var drawingCtx = Quagga.canvas.ctx.overlay,
                drawingCanvas = Quagga.canvas.dom.overlay;

            if (result) {
                if (result.boxes) {
                    drawingCtx.clearRect(
                        0,
                        0,
                        Number(drawingCanvas.getAttribute('width')),
                        Number(drawingCanvas.getAttribute('height'))
                    );
                    result.boxes
                        .filter(function (box) {
                            return box !== result.box;
                        })
                        .forEach(function (box) {
                            Quagga.ImageDebug.drawPath(box, { x: 0, y: 1 }, drawingCtx, {
                                color: 'green',
                                lineWidth: 2,
                            });
                        });
                }

                if (result.box) {
                    Quagga.ImageDebug.drawPath(result.box, { x: 0, y: 1 }, drawingCtx, {
                        color: '#00F',
                        lineWidth: 2,
                    });
                }

                if (result.codeResult && result.codeResult.code) {
                    Quagga.ImageDebug.drawPath(
                        result.line,
                        { x: 'x', y: 'y' },
                        drawingCtx,
                        { color: 'red', lineWidth: 3 }
                    );
                }
            }
        });

        Quagga.onDetected((data) => {
            const bipSound = new Audio('/sounds/bip.mp3');
            bipSound.play();
            setIdOtg(data.codeResult.code);
        });
    }, [sessionToken.token, history]);

    const agregarLista = () => {
        if (idOtg === '') {
            handleClickSnackbar('Digite o escanee la OTG', 'info');
            return;
        }

        findOneById({ id_otg: idOtg }).then((response) => {
            if (response.data === '') {
                handleClickSnackbar('La otg no se encuentra registrada', 'error');
            } else if (response.data.id_colaborador !== datosSession.id_colaborador) {
                handleClickSnackbar(
                    'Esta OTG no se registró en su almacén, no puede recibirla.',
                    'error'
                );
            } else {
                const noContiene = listaOtgs.some(otg => otg.id_otg === parseInt(idOtg));
                if (!noContiene && idOtg !== '') {
                    const estadoTransporte = 7;
                    const tipoTransporte = 'regreso';
                    const idColaborador = datosSession.id_colaborador;

                    setListaOtgs([
                        ...listaOtgs,
                        {
                            id_otg: parseInt(idOtg),
                            id_estado: estadoTransporte,
                            id_colaborador: idColaborador,
                            razon_cambio: 'Flujo normal',
                            tipo_transporete: tipoTransporte,
                        },
                    ]);
                    setIdOtg('');

                    handleClickSnackbar('OTG agregada', 'success');
                }
            }
        });
    };

    const eliminar = (e) => {
        setListaOtgs(
            listaOtgs.filter((otg) => otg.id_otg !== parseInt(e.target.value))
        );

        handleClickSnackbar('Otg quitada de la lista', 'success');
    };

    const registrar = () => {
        cambiarEstado(listaOtgs)
            .then(() => {
                setListaOtgs([]);
                setMostrarModal(false);

                handleClickSnackbar('Otgs registradas en tienda', 'success');
            })
            .catch((err) => console.log(err.message));
    };

    const modal = (
        <Dialog
            open={mostrarModal}
            onClose={() => {
                setMostrarModal(false);
            }}
        >
            <DialogTitle>Confirme que está recibiendo:</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {listaOtgs.map((otg, i) => {
                        return (
                            <Typography key={i} variant='body2' align='center'>
                                Número de OTG: {otg.id_otg}
                            </Typography>
                        );
                    })}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        setMostrarModal(false);
                    }}
                    variant='outlined'
                    sx={{ backgroundColor: 'red', color: 'white' }}
                >
                    Cancelar
                </Button>
                <Button
                    onClick={() => {
                        registrar();
                    }}
                    variant='contained'
                    color='primary'
                >
                    Confirmar
                </Button>
            </DialogActions>
        </Dialog>
    );

    return (
        <>
            {modal}
            <Grid container>
                <Typography align='center' variant='h6'>
                    Recibir producto
                </Typography>

                <Grid container justifyContent='center' sx={{ m: 3 }}>
                    <TextField
                        label='OTG'
                        autoFocus
                        value={idOtg}
                        onChange={(e) => {
                            setIdOtg(e.target.value);
                        }}
                    />
                    <Button
                        variant='contained'
                        onClick={() => {
                            agregarLista();
                        }}
                    >
                        Agregar
                    </Button>
                </Grid>
            </Grid>
            <div style={{ position: 'relative' }} id='camera' />
            <Grid container direction='column' justifyContent='center'>
                <Typography align='center' variant='h6'>
                    OTG's escaneadas
                </Typography>
                {listaOtgs.length === 0 ? (
                    <Typography align='center' variant='body1' paragraph>
                        No hay OTG's en la lista
                    </Typography>
                ) : (
                    <FormControl component='fieldset'>
                        <FormGroup>
                            {listaOtgs.map((otg, i) => {
                                return (
                                    <FormControlLabel
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                        }}
                                        key={i}
                                        value={otg.id_otg}
                                        control={
                                            <Checkbox
                                                onChange={(e) => {
                                                    eliminar(e);
                                                }}
                                                checked
                                            />
                                        }
                                        label={`Número de OTG: ${otg.id_otg}`}
                                        labelPlacement='start'
                                    />
                                );
                            })}
                        </FormGroup>
                    </FormControl>
                )}

                <Button
                    sx={{ width: '50%', margin: 'auto' }}
                    variant='contained'
                    onClick={() => {
                        if (datosSession.id_tipo === 6) {
                            handleClickSnackbar(
                                'No tienes permisos para realizar esta acción',
                                'error'
                            );
                        } else {
                            if (listaOtgs.length === 0) {
                                handleClickSnackbar('Agregue OTGs para registrarlas', 'error');
                            } else {
                                setMostrarModal(true);
                            }
                        }
                    }}
                >
                    Registrar
                </Button>
            </Grid>
        </>
    );
}
